angular.module('app').controller('CredentialsPageController',['$rootScope','$scope','$uibModal','$sce','$timeout','$window','$q','tools','modelstore','webservice','errorlog','parameters',
                                            function($rootScope,$scope,$uibModal,$sce,$timeout,$window,$q,tools,modelstore,webservice,errorlog,parameters) {
  var model=$scope.model;
  var script='public/reservationC';

  $scope.trustAsHtml = function() {
    var i,j;
    for(i in model.formbuttoncomponents) {
      for(j in labels) {
        model.formbuttoncomponents[i]['credentials'+labels[j]]=$sce.trustAsHtml(model.formbuttoncomponents[i][labels[j]]);
      }
    }
  };

  $scope.setPersonContactGsmAndCountry = function() {
    model.personContact.country=parameters.countryCallingCodes[model.personContact.countryCallingKey].countryId;
    if(!($scope.hasVoucher || model.delivery==='1') && model.personContact.country!=='BE') {
      model.personContact.postalcode='';
    }
    model.personContact.gsm=(model.personContact.number==='')?(''):('+'+model.personContact.countryCallingCode+'(0)'+model.personContact.number);
  };

  $scope.initDate = function(yearOffset) {
    var date=new Date();
    date.setFullYear(date.getFullYear()+yearOffset);
    return date;
  };

  $scope.takeawayInfoIsFilled = function() {
    var result;
    if(!$scope.isCatering()) {
      result=$q.resolve();
    } else if(model.delivery===null) {
      errorlog.error(tools.lang({
        'en':'Please select takeaway or delivery',
        'nl':'Gelieve afhaal of levering te selecteren',
        'fr':'Veuillez selectionner venir chercher sur place ou livraison'
      }));
      result=$q.reject();
    } else if(model.startdateISO===tools.today() && model.lastOrderHourPassed) {
      errorlog.error(tools.lang({
        'en':'The ordering is closed for today, last order before ',
        'nl':'De bestellingen voor vandaag zijn afgelopen, de laatste bestelling is om ',
        'fr':'Les commandes sont ferm\u00E9es pour aujourd\u0027hui, la derni\u00E8re commande est possible jusqu\u0027\u00E0 '
      })+tools.label(parameters.HRS[$scope.getMaxHrsDate()]));
      result=$q.reject();
    } else if(model.takeawayHrs===null) {
      errorlog.error(tools.lang({
        'en':'Please select the time',
        'nl':'Gelieve het tijdstip te selecteren',
        'fr':'Veuillez selectionner l\u0027heure'
      }));
      result=$q.reject();
    } else {
      result=$q.resolve();
    }
    return result;
  };

  $scope.startOnlinePaymentProcess = function() {
    if(!$scope.isUndefinedReferer()) {
      $scope.termsAndConditionsAreAccepted().then(function() {
        $scope.takeawayInfoIsFilled().then(function() {
          $scope.openVoucherModal().then(function(voucherCodes) {
            var params = $scope.createReservationParams(voucherCodes,[],{},true);
            var checkoutItems = [];
            for(var id in params.items) {
              checkoutItems.push(model.items[params.items[id].id]);
            }
            $scope.pushGTMCheckout(checkoutItems);
            $scope.setLeavePageBeforeUnloadTo(false);
            webservice.exclusiveCall('createToPayOnline',params,script,model).then(function(response) {
              if('formSubmit' in response) {
                $rootScope.$broadcast('onlinepayment.pay',response.formSubmit);
              } else if('voucher' in response) {
                $scope.redirectWhenFinished();
              } else if('redirect' in response) {
                $window.location.href=response.redirect;
              }
            });
          });
        });
      });
    }
  };

  $scope.createReservation = function() {
    $scope.termsAndConditionsAreAccepted().then(function() {
      var params=$scope.createReservationParams([],[],{},true);
      var checkoutItems=[];
      for(var id in params.items) {
        checkoutItems.push(model.items[params.items[id].id]);
      }
      $scope.pushGTMCheckout(checkoutItems);
      $scope.setLeavePageBeforeUnloadTo(false);
      webservice.exclusiveCall('createReservation',params,script,model).then(function() {
        $scope.redirectWhenFinished();
      });
    });
  };

  $scope.createReservationWithCreditcard = function() {
    $scope.termsAndConditionsAreAccepted().then(function() {
      var params=$scope.createReservationParams([],[],{},true);
      var checkoutItems=[];
      for(var id in params.items) {
        checkoutItems.push(model.items[params.items[id].id]);
      }
      $scope.pushGTMCheckout(checkoutItems);
      $scope.setLeavePageBeforeUnloadTo(false);
      var contactcreditcard=tools.clone(model.contactcreditcard);
      contactcreditcard.contact=params.visit.owningcontact;
      contactcreditcard.expirydate=tools.dateFromJava(new Date(model.contactcreditcard.expiryyear,model.contactcreditcard.expirymonth),0);
      params.contactcreditcards=tools.whitelist([contactcreditcard],['contact','unencryptedCardnumber','unencryptedCvc','expirydate']);
      webservice.exclusiveCall('createReservation',params,script,model).then(function() {
        $scope.redirectWhenFinished();
      });
    });
  };

  $scope.getOnlinePrice = function() {
    var price=0.0;
    if(angular.isUndefined(model.campaignPromotion) || model.campaignPromotion===null || !model.campaignOnlyForOnlinePayment) {
      price=$scope.getReservationPrice();
    } else {
      var promotionAmount=parseFloat(model.campaignPromotion.amount);
      var promotionPercent=model.campaignPromotion.percent;
      var promotionPerItem=model.campaignPromotion.peritem;
      var items=$scope.getItems();
      var itemLength=items.length;
      var i,item,activity,gross,promotionReductionAmount,itemReductionAmount,discountAllowed,itemType;
      for(i=0;i<itemLength;i++) {
        item=items[i];
        itemType=item.itm.substr(0,2);
        discountAllowed=true;
        if(itemType==='HP') {
          discountAllowed=parameters.HPD[item.itm.substr(2,6)].labelen.toLowerCase()!=='city tax';
        } else if(itemType==='PR') {
          discountAllowed=parameters.PRD[item.itm.substr(5,3)].field2==='1';
        }
        gross=item.quantity*item.grossunitprice;
        if(promotionPercent==='1') {
          promotionReductionAmount=Math.round(gross*promotionAmount)/100.0;
        } else {
          promotionReductionAmount=promotionAmount;
        }
        if(item.percent==='1') {
          itemReductionAmount=Math.round(gross*item.reductionamount)/100.0;
        } else {
          itemReductionAmount=item.reductionamount;
        }
        if('participants' in item && promotionReductionAmount>itemReductionAmount) {
          activity=model.activities[model.participants[item.participants[0]].activity];
          if(model.campaignFrmIds.indexOf(activity.frm)>=0 || model.campaignFrmIds.indexOf(activity.frm.substr(0,3)+'***')>=0 || promotionPerItem==='0') {
            price+=gross-promotionReductionAmount;
          } else {
            price+=item.nettopay;
          }
        } else if(promotionPerItem==='0' && discountAllowed) {
          price+=gross-promotionReductionAmount;
        } else {
          price+=item.nettopay;
        }
      }
    }
    return price;
  };

  model.page='credentials';
  $window.scrollTo(0, 0);
  $scope.dateOfBirthOpened=false;
  $scope.dateOfBirthOptions={
    maxDate: $scope.initDate(-12),
    minDate: $scope.initDate(-112),
    startingDay: 1,
    datepickerMode:'year',
    initDate:$scope.initDate(-30)
  };

  $scope.calculatePaymentOptions = function() {
    if($scope.isCatering() || tools.getLength(model.longTermBasket)===0) {
      $scope.payAtLocationIsAvailable=false;
      $scope.debetPaymentIsAvailable=true;
      $scope.reservationWithCreditcardIsAvailable=false;
    } else {
      $scope.payAtLocationIsAvailable=true;
      $scope.debetPaymentIsAvailable=true;
      $scope.reservationWithCreditcardIsAvailable=false;
      var i,j,longTermBasketContent,formbuttoncomponentReference,formbuttoncomponentCategory,act;
      for(i in model.longTermBasket) {
        longTermBasketContent=model.longTermBasket[i];
        for(j in longTermBasketContent) {
          formbuttoncomponentReference=longTermBasketContent[j];
          if(angular.isObject(formbuttoncomponentReference) && (formbuttoncomponentReference.formbuttoncomponent in model.formbuttoncomponents)) {
            formbuttoncomponentCategory=model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1;
            if(formbuttoncomponentCategory==='PR') {
              $scope.payAtLocationIsAvailable=false;
            } else if(formbuttoncomponentCategory==='HS') {
              $scope.payAtLocationIsAvailable=false;
              $scope.reservationWithCreditcardIsAvailable=true;
            } else if(formbuttoncomponentCategory==='AC') {
              act=model.activities[formbuttoncomponentReference.record].frm.substr(0,3);
              if(parameters.ACT[act].field7==='1') {
                $scope.payAtLocationIsAvailable=false;
              }
            }
          }
        }
      }
    }
  };

  $scope.calculateHasVoucher = function() {
    $scope.hasVoucher=false;
    for(var i in model.longTermBasket) {
      $scope.hasVoucher=$scope.basketContainsVoucher(model.longTermBasket[i]);
    }
  };

  $scope.takeawayHrsOptions=[];
  $scope.setTakeawayHrsOptions = function() {
    $scope.takeawayHrsOptions=[];
    if(model.delivery!==null) {
      var allOptions=model.restaurantAvailabilities[model.startdateISO];
      if(model.startdateISO>tools.today()) {
        $scope.takeawayHrsOptions=allOptions;
      } else {
        var delay=model.delivery==='0'?30:45;
        var minHrsDate=new Date();
        minHrsDate.setMinutes(minHrsDate.getMinutes()+delay);
        var minHrs=tools.hourFromJava(minHrsDate);
        model.lastOrderHourPassed=$scope.lastOrderHourPassed('00:00:00');
        if(!model.lastOrderHourPassed) {
          for(var i in allOptions) {
            if(allOptions[i]>=minHrs) {
              $scope.takeawayHrsOptions.push(allOptions[i]);
            }
          }
        }
      }
    }
  };

  $scope.setDelivery = function() {
    for(var i in model.meals) {
      model.meals[i].delivery=model.delivery;
    }
  };

  $scope.setTakeawayHrs = function() {
    for(var i in model.meals) {
      model.meals[i].hrs=model.takeawayHrs;
    }
  };

  $scope.getCreditcardYears = function() {
    var result=[];
    var currentYear=new Date().getFullYear();
    for(var i=0;i<20;i++) {
      result.push(String(currentYear+i));
    }
    return result;
  };

  var labels=['labelen','labelnl','labelfr'];
  model.lastOrderHourPassed=$scope.lastOrderHourPassed('00:00:00');
  $scope.calculatePaymentOptions();
  $scope.calculateHasVoucher();
  $scope.trustAsHtml();
  $scope.loaded.then(function() {
    $scope.trustAsHtml();
    $scope.calculateHasVoucher();
    model.delivery=$scope.isDeliverable()?null:'0';
    $scope.setDelivery();
    if(model.delivery!==null && $scope.isCatering()) {
      $scope.setTakeawayHrsOptions();
    }
    $scope.setCurrentStepIndexToMax();
  });
  $scope.$on('loaded',function() {
    $scope.calculatePaymentOptions();
    if(model.delivery!==null) {
      $scope.setTakeawayHrsOptions();
    }
    $scope.setCurrentStepIndexToMax();
  });

}]);
