angular.module('app').controller('DetailsPageController',['$scope','$filter','config','tools','webservice',
                                            function($scope,$filter,config,tools,webservice) {
  var model=$scope.model;
  var script='public/reservationC';

  $scope.parentProceed = function(shortTermBasket,collections,params) {
    model.detailsPageCollections=collections;
    if($scope.isSiteMode) {
      var localModel={};
      webservice.exclusiveCall('validateEvents',params,script,localModel).then(function() {
        for(var i in localModel.items) {
          collections.items[i]=localModel.items[i];
        }
        $scope.updateRecordsFromLongTermBasket(shortTermBasket);
        $scope.addRecordsFromShortTermBasket(shortTermBasket,collections,true);
        $scope.redirectToCredentials();
      });
    } else {
      $scope.termsAndConditionsAreAccepted().then(function() {
        var localModel={};
        webservice.exclusiveCall('createToPayCtep',params,script,localModel).then(function(response) {
          model.ctepVisit=response.visit;
          model.ctepVisitAmount=response.amount;
          tools.redirect('paymentInstructions');
        });
      });
    }
  };

  model.page='details';
  $scope.confirmWording=$scope.isSiteMode?{'en':'Proceed','nl':'Verder','fr':'\u00C9tape suivante'}:{'en':'Pay','nl':'Betalen','fr':'Payer'};
  $scope.loaded.then(function() {
    if(model.detailsPageBasket===null) {
      model.detailsPageBasket=model.specificSaleFormbuttoncomponents.map(function(formbuttoncomponent) {
        return {'formbuttoncomponent':formbuttoncomponent.id};
      });
    } else {
      var toGTM = [];
      var items=$scope.getItems(model.detailsPageBasket);
      for(var id in items) {
        var item=model.items[items[id].id];
        if(angular.isDefined(item.itm)) {
          toGTM.push(item);
        }
      }
      $scope.pushGTMRemoveFromCart(toGTM);
    }

    if(model.currentStepIndex === 0) {
      $scope.incrementCurrentStepIndex();
    } else if(model.currentStepIndex === tools.getLength($scope.getFilteredFca()) + 1) {
      $scope.decrementCurrentStepIndex();
    }

    $scope.shortTermBasket=model.detailsPageBasket;
  });

}]);
