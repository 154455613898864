angular.module('app').controller('DetailsComponentController',['$scope','$sce','$timeout','tools','modelstore','parameters','webservice','user','errorlog',
                                            function($scope,$sce,$timeout,tools,modelstore,parameters,webservice,user,errorlog) {
  var script='public/reservationC';
  var model={};
  var parentModel=$scope.model;
  $scope.parentModel=parentModel;
  $scope.model=model;

  $scope.filterShortTermBasket = function(formbuttoncomponentReference) {
    var formbuttoncomponent=parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent];
    return formbuttoncomponent.param1==='HS' || !('formbutton' in formbuttoncomponent) || parentModel.formbuttons[formbuttoncomponent.formbutton].singlequantity==='0';
  };

  $scope.addCollections = function(collections) {
    angular.forEach(collections,function(records,collectionName) {
      for(var i in records) {
        modelstore.addRecord(collectionName,tools.clone(records[i]),model);
      }
    });
  };

  $scope.load = function() {
    if(!('shortTermBasket' in $scope) || !$scope.shortTermBasket || !(0 in $scope.shortTermBasket)) {
      return;
    }
    var i;
    var singlequantity=false;
    model.hasfixeddate=false;
    model.longTermBasket=tools.clone(parentModel.longTermBasket);
    var firstFormbuttoncomponent=parentModel.formbuttoncomponents[$scope.shortTermBasket[0].formbuttoncomponent];
    if('formbutton' in firstFormbuttoncomponent) {
      model.firstFormbutton=parentModel.formbuttons[firstFormbuttoncomponent.formbutton];
      model.firstFormbutton.trustedRawhtml=$sce.trustAsHtml(model.firstFormbutton['rawhtml'+user.lang]);
      singlequantity=model.firstFormbutton.singlequantity==='1';
      model.hasfixeddate=model.firstFormbutton.hasfixeddate==='1';
    } else {
      model.firstFormbutton=null;
    }
    model.filteredShortTermBasket=[];
    if(singlequantity) {
      model.filteredShortTermBasket=[{'formbuttoncomponent':'pack'}];
      var firstPackFormbuttoncomponentReference=$scope.shortTermBasket.reduce(function(acc,formbuttoncomponentReference) {
        var formbuttoncomponent=parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent];
        return (formbuttoncomponent.param1!=='PR' && ('record' in formbuttoncomponentReference))?formbuttoncomponentReference:acc;
      },null);
      if(firstPackFormbuttoncomponentReference===null) {
        model.packQuantity='1';
      } else {
        var firstNonProductFormbuttoncomponent=parentModel.formbuttoncomponents[firstPackFormbuttoncomponentReference.formbuttoncomponent];
        if(firstNonProductFormbuttoncomponent.param1==='AC') {
          model.packQuantity=String(tools.inverseJoin(tools.join(parentModel.activities[firstPackFormbuttoncomponentReference.record],'participants',parentModel),'items','item',parentModel).reduce(function(acc,item) {
            return Math.max(tools.numberIn(item.quantity),acc);
          },0));
        } else if(firstNonProductFormbuttoncomponent.param1==='ME') {
          model.packQuantity=String(tools.inverseJoin(tools.join(parentModel.meals[firstPackFormbuttoncomponentReference.record],'dishes',parentModel),'items','item',parentModel).reduce(function(acc,item) {
            return Math.max(tools.numberIn(item.quantity),acc);
          },0));
        } else if(firstNonProductFormbuttoncomponent.param1==='HS') {
          model.packQuantity=tools.numberIn(parentModel.items[parentModel.hotelreservationcategories[firstPackFormbuttoncomponentReference.record].item].quantity);
        }
        if(tools.numberIn(firstNonProductFormbuttoncomponent.quantity)!==0) {
          model.packQuantity=Math.floor(model.packQuantity/tools.numberIn(firstNonProductFormbuttoncomponent.quantity));
        }
      }
    } else {
      model.filteredShortTermBasket=$scope.shortTermBasket.filter(function(formbuttoncomponentReference) {
        var product,hotelreservationcategory,result;
        var formbuttoncomponent=parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent];
        if(formbuttoncomponent.param1==='PR' && formbuttoncomponent.param2==='VOU' && ('record' in formbuttoncomponentReference)) {
          product=parentModel.products[formbuttoncomponentReference.record];
          result=((angular.isDefined(product.isNotShadow) && product.isNotShadow) || angular.isUndefined(product.isNotShadow));
        } else if(formbuttoncomponent.param1==='HS' && ('record' in formbuttoncomponentReference)) {
          hotelreservationcategory=parentModel.hotelreservationcategories[formbuttoncomponentReference.record];
          result=((angular.isDefined(hotelreservationcategory.isNotShadow) && hotelreservationcategory.isNotShadow) || angular.isUndefined(hotelreservationcategory.isNotShadow));
        } else {
          result=true;
        }
        return result;
      });
    }
    model.accordionStatus=model.filteredShortTermBasket.map(function() {
      return false;
    });
    model.accordionStatus[0]=true;
    model.parkingMode=true;
    for(i in $scope.shortTermBasket) {
      if(angular.isObject($scope.shortTermBasket[i]) && 'formbuttoncomponent' in $scope.shortTermBasket[i]) {
        model.parkingMode=model.parkingMode && parentModel.formbuttoncomponents[$scope.shortTermBasket[i].formbuttoncomponent].param2==='PRKTIC';
      }
    }
    if($scope.shortTermBasket.length===1 && !('record' in tools.first($scope.shortTermBasket)) && model.parkingMode) {
      var blueprintFormbuttoncomponentReference=tools.first($scope.shortTermBasket);
      $scope.shortTermBasket=[];
      var days=tools.exclude($scope.getActivityDays('PRKTIC'),{'id':''});
      var blueprintActivity={
        'visit':'-1',
        'frm':'PRKTIC',
        'hrs':'09:00:00'
      };
      var blueprintParticipant={'unt':'PRK***DA'};
      var formbuttoncomponentReference,activity,item,participant;
      for(i in days) {
        activity=tools.clone(blueprintActivity);
        activity.id=tools.getMinIdMinusOne(tools.toArray(parentModel.activities).concat(tools.toArray(model.activities)));
        activity.day=days[i].id;
        modelstore.addRecord('activities',activity,model);
        formbuttoncomponentReference=tools.clone(blueprintFormbuttoncomponentReference);
        formbuttoncomponentReference.record=activity.id;
        $scope.shortTermBasket.push(formbuttoncomponentReference);
        item=$scope.createItem(days.length===1?'1':'0');
        modelstore.addRecord('items',item,model);
        participant=tools.clone(blueprintParticipant);
        participant.id=tools.getMinIdMinusOne(tools.toArray(parentModel.participants).concat(tools.toArray(model.participants)));
        participant.activity=activity.id;
        participant.item=item.id;
        modelstore.addRecord('participants',participant,model);
      }
    } else {
      var labels=['labelen','labelnl','labelfr'];
      var startdateISO=tools.dateFromJava(parentModel.startdate);
      angular.forEach($scope.shortTermBasket,function(formbuttoncomponentReference) {
        var j,formbuttoncomponent,day,activity,participants,untId,meal,dishes,hotelreservationcategory,hotelreservationcategories,hotelreservationproducts,product,item,hpd;
        formbuttoncomponent=parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent];
        for(j in labels) {
          if(labels[j] in formbuttoncomponent) {
            formbuttoncomponent['detailsModal'+labels[j]]=$sce.trustAsHtml(formbuttoncomponent[labels[j]]);
          }
        }
        if(formbuttoncomponent.param1==='AC' || formbuttoncomponent.param1==='ME') {
          if(singlequantity || model.hasfixeddate) {
            day=tools.dateFromJava(tools.addDays(parentModel.startdate,parseInt(formbuttoncomponent.dayoffset,10)));
          } else {
            day=parentModel.multipleDays==='1'?'':startdateISO;
          }
        }
        var codeToCollectionName={'AC':'activities','ME':'meals','HS':'hotelreservationcategories','PR':'products'};
        if(('record' in formbuttoncomponentReference) && !(formbuttoncomponentReference.record in parentModel[codeToCollectionName[formbuttoncomponent.param1]])) {
          delete formbuttoncomponentReference.record;
        }
        if(formbuttoncomponent.param1==='AC') {
          if('record' in formbuttoncomponentReference) {
            activity=parentModel.activities[formbuttoncomponentReference.record];
            participants=tools.join(activity,'participants',parentModel);
            $scope.addCollections({'activities':[activity],'participants':participants,'items':tools.inverseJoin(participants,'items','item',parentModel)});
          } else {
            activity={
              'id':tools.getMinIdMinusOne(tools.toArray(parentModel.activities).concat(tools.toArray(model.activities))),
              'visit':'-1',
              'act':formbuttoncomponent.param2.substr(0,3),
              'day':day,
              'hrs':formbuttoncomponent.hrs
            };
            formbuttoncomponentReference.record=activity.id;
            modelstore.addRecord('activities',activity,model);
            for(untId in parameters.UNT) {
              if($scope.matchesFormula(untId,formbuttoncomponent.param2)) {
                item=$scope.createItem('0');
                if(formbuttoncomponent.param2==='PRKTIC') {
                  item.quantity='1';
                } else if(parameters.UNT[untId].field6!=='') {
                  item.quantity=parameters.UNT[untId].field6;
                } else if(parameters.UNT[untId].field2==='1' && tools.amountIn(formbuttoncomponent.quantity)!==0.0) {
                  item.quantity=formbuttoncomponent.quantity;
                }
                modelstore.addRecord('items',item,model);
                modelstore.addRecord('participants',{
                  'id':tools.getMinIdMinusOne(tools.toArray(parentModel.participants).concat(tools.toArray(model.participants))),
                  'activity':formbuttoncomponentReference.record,
                  'item':item.id,
                  'unt':untId
                },model);
              }
            }
            $scope.updateActivityFormulasDaysAndHoursOptionsForFormbuttoncomponentReference(formbuttoncomponentReference);
          }
        } else if(formbuttoncomponent.param1==='ME') {
          if('record' in formbuttoncomponentReference) {
            meal=parentModel.meals[formbuttoncomponentReference.record];
            dishes=tools.join(meal,'dishes',parentModel);
            $scope.addCollections({'meals':[meal],'dishes':dishes,'items':tools.inverseJoin(dishes,'items','item',parentModel)});
          } else {
            meal={
              'id':tools.getMinIdMinusOne(tools.toArray(parentModel.meals).concat(tools.toArray(model.meals))),
              'visit':'-1',
              'res':formbuttoncomponent.param2.substr(0,3),
              'day':day,
              'hrs':$scope.isCatering()?parentModel.takeawayHrs:formbuttoncomponent.hrs,
              'delivery':parentModel.delivery
            };
            formbuttoncomponentReference.record=meal.id;
            modelstore.addRecord('meals',meal,model);
            item=$scope.createItem(tools.amountIn(formbuttoncomponent.quantity)===0.0?'1':formbuttoncomponent.quantity);
            modelstore.addRecord('items',item,model);
            modelstore.addRecord('dishes',{
              'id':tools.getMinIdMinusOne(tools.toArray(parentModel.dishes).concat(tools.toArray(model.dishes))),
              'meal':formbuttoncomponentReference.record,
              'item':item.id,
              'dsh':formbuttoncomponent.param2
            },model);
            if(!($scope.isCatering())) {
              $scope.updateMealDaysAndHoursOptionsForFormbuttoncomponentReference(formbuttoncomponentReference);
            }
          }
        } else if(formbuttoncomponent.param1==='HS') {
          if('record' in formbuttoncomponentReference) {
            hotelreservationcategories=parentModel.hotelreservationcategories[formbuttoncomponentReference.record];
            hotelreservationproducts=tools.join(hotelreservationcategories,'hotelreservationproducts',parentModel);
            $scope.addCollections({
              'hotelreservationcategories':hotelreservationcategories,
              'hotelreservationproducts':hotelreservationproducts,
              'items':tools.inverseJoin(hotelreservationcategories,'items','item',parentModel)
                .concat(tools.inverseJoin(hotelreservationproducts,'items','item',parentModel))
            });
          } else {
            var quantity=tools.amountIn(formbuttoncomponent.quantity)===0.0?'1':formbuttoncomponent.quantity;
            item=$scope.createItem('1');
            modelstore.addRecord('items',item,model);
            var requestedhsc=formbuttoncomponent.param2;
            var hsc=parameters.HSC[requestedhsc];
            hotelreservationcategory={
              'isNotShadow':true,
              'id':tools.getMinIdMinusOne(tools.toArray(parentModel.hotelreservationcategories).concat(tools.toArray(model.hotelreservationcategories))),
              'visit':'-1',
              'htl':requestedhsc.substr(0,3),
              'item':item.id,
              'requestedhsc':requestedhsc,
              'startdate':parentModel.startdateISO,
              'starthrs':formbuttoncomponent.hrs,
              'enddate':parentModel.enddateISO,
              'hra':formbuttoncomponent.param3,
              'adultcount':hsc.field3,
              'childcount':hsc.field4,
              'quantity':quantity,
              'hpdoptions':('HSCHPDA' in parameters.structuredCombinations && formbuttoncomponent.param2 in parameters.structuredCombinations.HSCHPDA)?parameters.structuredCombinations.HSCHPDA[formbuttoncomponent.param2].reduce(function(acc,hpdId) {
                acc.push({'id':hpdId,'disabled':false,'chosen':false});
                return acc;
              },[]):[]
            };
            var hraPerHotel=$scope.getHraPerHotel();
            if(hraPerHotel!==null && (hotelreservationcategory.htl in hraPerHotel)) {
              hotelreservationcategory.hra=hraPerHotel[hotelreservationcategory.htl];
            } else if(formbuttoncomponent.param3!=='') {
              hotelreservationcategory.hra=formbuttoncomponent.param3;
            }
            for(j in parameters.HPD) {
              if(j.substr(0,3)===hotelreservationcategory.htl) {
                hpd=parameters.HPD[j];
                if(hpd.labelen==='City Tax' && hpd.field2==='PerPersonPerTimeUnit' && hpd.field5==='1') {
                  hotelreservationcategory.hpdoptions.push({'id':j,'disabled':true,'chosen':true});
                  break;
                }
              }
            }
            var hpdoption,productItem;
            for(j in hotelreservationcategory.hpdoptions) {
              hpdoption=hotelreservationcategory.hpdoptions[j];
              if(hpdoption.chosen) {
                productItem=$scope.createItem('1');
                modelstore.addRecord('items',productItem,model);
                modelstore.addRecord('hotelreservationproducts',{
                  'id':tools.getMinIdMinusOne(tools.toArray(parentModel.hotelreservationproducts).concat(tools.toArray(model.hotelreservationproducts))),
                  'reservationcategory':hotelreservationcategory.id,
                  'hpd':hpdoption.id,
                  'item':productItem.id
                },model);
              }
            }
            formbuttoncomponentReference.record=hotelreservationcategory.id;
            modelstore.addRecord('hotelreservationcategories',hotelreservationcategory,model);
            if(tools.amountIn(quantity)>1) {
              $scope.updateRoomQuantity(hotelreservationcategory,formbuttoncomponentReference);
            }
          }
        } else if(formbuttoncomponent.param1==='PR') {
          if('record' in formbuttoncomponentReference) {
            product=parentModel.products[formbuttoncomponentReference.record];
            $scope.addCollections({'products':[product],'items':[parentModel.items[product.item]]});
          } else {
            item=$scope.createItem(tools.amountIn(formbuttoncomponent.quantity)===0.0?parameters.PRD[formbuttoncomponent.param2].field1:formbuttoncomponent.quantity);
            modelstore.addRecord('items',item,model);
            product={
              'isNotShadow':true,
              'id':tools.getMinIdMinusOne(tools.toArray(parentModel.products).concat(tools.toArray(model.products))),
              'visit':'-1',
              'item':item.id,
              'prd':formbuttoncomponent.param2,
              'quantity':'1',
              'comment':(tools.amountIn(formbuttoncomponent.quantity)>0.0 && formbuttoncomponent.param2==='VOU')?formbuttoncomponent['label'+user.lang]:''
            };
            formbuttoncomponentReference.record=product.id;
            modelstore.addRecord('products',product,model);
          }
        }
      });
      if($scope.isSpecificSale) {
        $scope.addRecordsFromShortTermBasket($scope.shortTermBasket,$scope.getEventsAndProducts($scope.shortTermBasket,model),false);
      }
    }
    $scope.calculatePrices().then(function() {
      if(singlequantity) {
        $scope.onPackQuantityChange();
      }
    });
  };

  // $scope.packHasKayak = function() {
  //   return $scope.shortTermBasket.reduce(function(acc,formbuttoncomponentReference) {
  //     return acc || (parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1==='AC' && model.activities[formbuttoncomponentReference.record].frm.substr(0,3)==='KAY');
  //   },false);
  // };

  $scope.createItem = function(quantity) {
    return {
      'id':tools.getMinIdMinusOne(tools.toArray(parentModel.items).concat(tools.toArray(model.items))),
      'visit':'-1',
      'reductionamount':'0',
      'grossunitprice':'0',
      'quantity':quantity
    };
  };

  $scope.getActivityFrms = function(frmWithWildcard) {
    var result={};
    if(frmWithWildcard.substr(3,3)==='***') {
      var act=frmWithWildcard.substr(0,3);
      for(var frm in parameters.FRM) {
        if(frm.substr(0,3)===act && (frm in $scope.getActivityAvailabilities()) && $scope.getActivityAvailabilities()[frm].available) {
          result[frm]=parameters.FRM[frm];
        }
      }
    } else {
      result[frmWithWildcard]=parameters.FRM[frmWithWildcard];
    }
    return result;
  };

  $scope.getHotelreservationcategoryHscs = function(hscWithWildcard) {
    var result={};
    if(hscWithWildcard.substr(3,3)==='***') {
      var htl=hscWithWildcard.substr(0,3);
      for(var hsc in parameters.HSC) {
        if(hsc.substr(0,3)===htl && hsc in parentModel.hotelAvailabilities && parentModel.hotelAvailabilities[hsc]>0) {
          result[hsc]=parameters.HSC[hsc];
        }
      }
    } else {
      result[hscWithWildcard]=parameters.HSC[hscWithWildcard];
    }
    return result;
  };

  $scope.getMealDshs = function(dshWithWildcard) {
    var result={};
    if(dshWithWildcard.substr(3,3)==='***') {
      var res=dshWithWildcard.substr(0,3);
      for(var dsh in parameters.DSH) {
        if(dsh.substr(0,3)===res && dsh in parentModel.allRestaurantAvailabilities && parentModel.allRestaurantAvailabilities[dsh].available) {
          result[dsh]=parameters.DSH[dsh];
        }
      }
    } else {
      result[dshWithWildcard]=parameters.DSH[dshWithWildcard];
    }
    return result;
  };

  $scope.getActivityDays = function(formula) {
    var result={};
    if(formula in $scope.getActivityAvailabilities()) {
      var days=$scope.getActivityAvailabilities()[formula].days;
      var i,day,sDay,option;
      for(i in days) {
        day=days[i];
        sDay=day.day;
        if(day.available && sDay>=parentModel.startdateISO && (sDay<=parentModel.enddateISO || (sDay<=parentModel.startdateISO && angular.isUndefined(parentModel.enddate)))) {
          option={'id':sDay};
          option['label'+user.lang]=tools.dateOutLongLang(sDay,user.lang);
          result[sDay]=option;
        }
      }
    }
    if(tools.getLength(result)!==1) {
      result['']={'id':'','labelen':'-- Day --','labelnl':'-- Dag --','labelfr':'-- Jour --'};
    }
    return result;
  };

  $scope.getMealDays = function(restaurant) {
    var result={};
    if(restaurant in parentModel.allRestaurantAvailabilities) {
      var days=parentModel.allRestaurantAvailabilities[restaurant].days;
      var i,day,sDay,option;
      for(i in days) {
        day=days[i];
        sDay=day.day;
        option={'id':sDay};
        option['label'+user.lang]=tools.dateOutLongLang(sDay,user.lang);
        result[sDay]=option;
      }
    }
    if(tools.getLength(result)!==1) {
      result['']={'id':'','labelen':'-- Day --','labelnl':'-- Dag --','labelfr':'-- Jour --'};
    }
    return result;
  };

  $scope.getDurationHours = function(frmId) {
    var frm=parameters.FRM[frmId];
    return tools.amountIn(frm.field1)+tools.amountIn(frm.field5)/8.0;
  };

  $scope.getEndtime = function(hrsId,frmId) {
    return tools.hourFromJava(new Date(tools.timestampToJava('1970-01-01 '+hrsId).getTime()+$scope.getDurationHours(frmId)*3600*1000));
  };

  $scope.getActivityHours = function(frmId,days) {
    var result={};
    if(frmId in $scope.getActivityAvailabilities()) {
      var hrsList,i,availabilityHrs,hrsId,day,j;
      for(i in days) {
        day=days[i].id;
        if(day in $scope.getActivityAvailabilities()[frmId].days) {
          hrsList=$scope.getActivityAvailabilities()[frmId].days[day].hrs;
          for(j in hrsList) {
            availabilityHrs=hrsList[j];
            if(availabilityHrs.available) {
              hrsId=availabilityHrs.hrs;
              result[hrsId]={'id':hrsId,'label':tools.label(parameters.HRS[hrsId])};
            }
          }
        }
      }
    }
    if(tools.getLength(result)!==1) {
      result['']={'id':'','label':tools.lang({'en':'-- Hour --','nl':'-- Uur --','fr':'-- Heure --'})};
    }
    return result;
  };

  $scope.getMealHours = function(resId,day) {
    var result={};
    if(resId in parentModel.allRestaurantAvailabilities && day in parentModel.allRestaurantAvailabilities[resId].days) {
      var restaurantAvailability,i,availabilityHrs,hrsId;
      restaurantAvailability=parentModel.allRestaurantAvailabilities[resId].days[day].hrs;
      for(i in restaurantAvailability) {
        availabilityHrs=restaurantAvailability[i];
        hrsId=availabilityHrs.hrs;
        result[hrsId]={'id':hrsId,'label':tools.label(parameters.HRS[hrsId])};
      }
    }
    if(tools.getLength(result)!==1) {
      result['']={'id':'','label':tools.lang({'en':'-- Hour --','nl':'-- Uur --','fr':'-- Heure --'})};
    }
    return result;
  };

  $scope.updateActivityFormulasDaysAndHoursOptionsForFormbuttoncomponentReference = function(formbuttoncomponentReference) {
    return $scope.updateActivityFormulasDaysAndHoursOptions(
      model.activities[formbuttoncomponentReference.record],
      parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param2
    );
  };

  $scope.updateActivityFormulasDaysAndHoursOptions = function(activity,frmWithWildcard) {
    var frms=$scope.getActivityFrms(frmWithWildcard);
    activity.frms=tools.toArray(frms);
    if(tools.getLength(activity.frms)===1) {
      activity.frm=tools.first(activity.frms).id;
    } else if(!(angular.isDefined(activity.frm) && activity.frm in frms)) {
      var defaultFrms=tools.match(activity.frms,{'field2':'1'});
      if(defaultFrms.length>0) {
        activity.frm=defaultFrms[0].id;
      } else {
        activity.frm='';
      }
    }
    var days=$scope.getActivityDays(activity.frm);
    activity.days=tools.exclude(days,{'id':''});
    var dayLen=tools.getLength(activity.days);
    if($scope.isKioskMode && activity.act!=='' && dayLen===0) {
      tools.redirect('notAvailableToday');
      return;
    } else if(dayLen===1) {
      activity.day=tools.first(activity.days).id;
    } else if(!(activity.day in days)) {
      activity.day='';
    }
    var hrss=$scope.getActivityHours(activity.frm,(activity.day==='' && activity.hrs!=='' && dayLen>1?activity.days:[{'id':activity.day}]));
    activity.hrss=hrss;
    if(tools.getLength(activity.hrss)===1) {
      activity.hrs=tools.first(activity.hrss).id;
    } else if(!(activity.hrs in hrss)) {
      activity.hrs='';
    }
    // capacityHrs
    var capacityHrs=null;
    if(activity.frm in $scope.getActivityAvailabilities()) {
      var maxLength=0;
      var capacityDays=$scope.getActivityAvailabilities()[activity.frm].days;
      var i,hrs,len;
      for(i in capacityDays) {
        hrs=capacityDays[i].hrs;
        len=tools.getLength(hrs);
        if(len>maxLength) {
          maxLength=len;
          capacityHrs=hrs;
        }
      }
    }
    capacityHrs=tools.clone(capacityHrs);
    if(activity.frm in parameters.FRM) {
      var maxCapacityHrs=tools.max(capacityHrs,'hrs');
      var lastArrival=parameters.FRM[activity.frm].field9;
      if(maxCapacityHrs.hrs<lastArrival) {
        capacityHrs[lastArrival]={'hrs':lastArrival,'load':maxCapacityHrs.load,'cap':maxCapacityHrs.cap,'available':maxCapacityHrs.available,'lastDeparture':maxCapacityHrs.hrs};
      }
    }
    activity.capacityHrs=capacityHrs;
  };

  $scope.untHasCapacity = function(activity,unt) {
    var result=true;
    if(activity.frm in parentModel.hrsLists) {
      if(activity.day in parentModel.hrsLists[activity.frm].days) {
        if(activity.hrs in parentModel.hrsLists[activity.frm].days[activity.day].hrs) {
          if(unt.id in parentModel.hrsLists[activity.frm].days[activity.day].hrs[activity.hrs].left) {
            result=parentModel.hrsLists[activity.frm].days[activity.day].hrs[activity.hrs].left[unt.id]>0;
          }
        }
      }
    }
    return result;
  };

  $scope.updateMealDaysAndHoursOptionsForFormbuttoncomponentReference = function(formbuttoncomponentReference) {
    return $scope.updateMealDaysAndHoursOptions(
      model.meals[formbuttoncomponentReference.record],
      parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param2
    );
  };

  $scope.updateMealDaysAndHoursOptions = function(meal,dshWithWildcard) {
    var dshs=$scope.getMealDshs(dshWithWildcard);
    meal.dshs=tools.toArray(dshs);
    if(tools.getLength(meal.dshs)===1) {
      meal.dsh=tools.first(meal.dshs).id;
    } else if(!(meal.dsh in dshs)) {
      meal.dsh='';
    }
    var days=$scope.getMealDays(meal.dsh.substr(0,3));
    meal.days=tools.exclude(days,{'id':''});
    var dayLen=tools.getLength(meal.days);
    if($scope.isKioskMode && meal.dsh!=='' && dayLen===0) {
      tools.redirect('notAvailableToday');
      return;
    } else if(dayLen===1) {
      meal.day=tools.first(meal.days).id;
    } else if(!(meal.day in days)) {
      meal.day='';
    }
    var hrss=$scope.getMealHours(meal.dsh.substr(0,3),meal.day);
    meal.hrss=hrss;
    if(tools.getLength(meal.hrss)===1) {
      meal.hrs=tools.first(meal.hrss).id;
    } else if(!(meal.hrs in hrss)) {
      meal.hrs='';
    }
  };

  // $scope.updateAllActivityFormulasDaysAndHoursOptions = function() {
  //   var activityFormbuttoncomponentReferences=$scope.shortTermBasket.filter(function(formbuttoncomponentReference) {
  //     return parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1==='AC';
  //   });
  //   var i;
  //   for(i in activityFormbuttoncomponentReferences) {
  //     $scope.updateActivityFormulasDaysAndHoursOptionsForFormbuttoncomponentReference(activityFormbuttoncomponentReferences[i]);
  //   }
  // };

  $scope.getCapacityHrsLabel = function(activity,index) {
    if(activity && ('frm' in activity) && (activity.frm in $scope.getActivityAvailabilities())) {
      var hrsList=tools.toArray(activity.capacityHrs);
      var hrsListLength=tools.getLength(hrsList);
      var minimumLength=0;
      var realIndex=0;
      if(index===0) {
        minimumLength=1;
        realIndex=0;
      } else if(index===1) {
        minimumLength=2;
        realIndex=hrsListLength-1;
      }
      if(hrsListLength>=minimumLength) {
        return hrsList[realIndex].hrs;
      }
    }
  };

  $scope.getValidActivitiesParticipantsAndItems = function() {
    var result={
      'activities':[],
      'participants':[]
    };
    var activities=$scope.getActivities();
    var i,activity;
    for(i in activities) {
      activity=activities[i];
      if(activity.frm!=='' && activity.day!=='' && activity.hrs!=='') {
        result.activities.push(activity);
        result.participants=result.participants.concat($scope.getParticipants(activity));
      }
    }
    result.items=tools.inverseJoin(result.participants,'items','item',model);
    return result;
  };

  $scope.mergeObjects = function(a,b,field) {
    var result={};
    var i,object;
    for(i in a) {
      object=a[i];
      result[object[field]]=object;
    }
    for(i in b) {
      object=b[i];
      result[object[field]]=object;
    }
    return tools.toArray(result);
  };

  $scope.calculatePrices = function() {
    var shortTermEvents=tools.clone($scope.getEventsAndProducts($scope.shortTermBasket,model));
    var i,activity,meal,item;
    for(i=0;i<shortTermEvents.activities.length;i++) {
      activity=shortTermEvents.activities[i];
      if(activity.frm==='') {
        activity.frm=tools.first(activity.frms).id;
        $scope.updateActivityFormulasDaysAndHoursOptions(activity,activity.frm);
      }
      if(activity.day==='') {
        if(activity.days.length>0) {
          activity.day=tools.first(activity.days).id;
        } else {
          activity.day=parentModel.startdateISO;
        }
        $scope.updateActivityFormulasDaysAndHoursOptions(activity,activity.frm);
      }
      if(activity.hrs==='' && tools.getLength(activity.hrss)>0) {
        activity.hrs=tools.first(activity.hrss).id;
        activity.hasDummyHrs=true;
      }
    }
    for(i=0;i<shortTermEvents.meals.length;i++) {
      meal=shortTermEvents.meals[i];
      if(meal.dsh==='') {
        meal.dsh=tools.first(meal.dshs).id;
        $scope.updateMealDaysAndHoursOptions(meal,meal.dsh);
      }
      if(meal.day==='') {
        if(meal.days.length>0) {
          meal.day=tools.first(meal.days).id;
        } else {
          meal.day=parentModel.startdateISO;
        }
        $scope.updateMealDaysAndHoursOptions(meal,meal.dsh);
      }
      if(meal.hrs==='' && tools.getLength(meal.hrss)>0) {
        meal.hrs=tools.first(meal.hrss).id;
        meal.hasDummyHrs=true;
      }
    }
    for(i in shortTermEvents.items) {
      item=shortTermEvents.items[i];
      if(tools.amountIn(item.quantity)<=0.0) {
        item.quantity='1';
      }
    }
    var longTermEvents=tools.clone($scope.getEventsAndProducts([].concat.apply([], parentModel.longTermBasket),parentModel));
    var tempModel={};
    model.calculatingPrices=true;
    var visit={'campaign':''};
    if(!parentModel.campaignOnlyForOnlinePayment) {
      visit={'campaign':parentModel.campaignCode};
    }
    return webservice.call('calculatePrices',$scope.whitelistParams({
      'contacts':[{}],
      'contactexternalids':[],
      'visit':visit,
      'activities':$scope.mergeObjects(longTermEvents.activities,shortTermEvents.activities,'id'),
      'participants':$scope.mergeObjects(longTermEvents.participants,shortTermEvents.participants,'id'),
      'meals':$scope.mergeObjects(longTermEvents.meals,shortTermEvents.meals,'id'),
      'dishes':$scope.mergeObjects(longTermEvents.dishes,shortTermEvents.dishes,'id'),
      'hotelreservationcategories':$scope.mergeObjects(longTermEvents.hotelreservationcategories,shortTermEvents.hotelreservationcategories,'id'),
      'hotelreservationproducts':$scope.mergeObjects(longTermEvents.hotelreservationproducts,shortTermEvents.hotelreservationproducts,'id'),
      'products':$scope.mergeObjects(longTermEvents.products,shortTermEvents.products,'id'),
      'items':$scope.mergeObjects(longTermEvents.items,shortTermEvents.items,'id'),
      'ffo':$scope.ffo
    }),script,tempModel).then(function(response) {
      model.calculatingPrices=false;
      if(response) {
        var j,tempItem;
        for(j in tempModel.items) {
          tempItem=tempModel.items[j];
          if(tempItem.id in model.items) {
            model.items[tempItem.id].grossunitprice=tools.amountIn(tempItem.grossunitprice);
            if(tempItem.reductionpercent==='1') {
              model.items[tempItem.id].netunitprice=Math.round(tools.amountIn(tempItem.grossunitprice)*(100-tools.amountIn(tempItem.reductionamount)))/100;
            } else {
              model.items[tempItem.id].netunitprice=Math.round((tools.amountIn(tempItem.grossunitprice)-tools.amountIn(tempItem.reductionamount)/tools.amountIn(tempItem.quantity))*100)/100;
            }
          }
        }
      }
    });
  };

  function interpolateHslColors(a,b,fraction) {
    var h;
    if((360+b.h-a.h)%360 < (360+a.h-b.h)%360) {
      h=(360+a.h+((360+b.h-a.h)%360)*fraction)%360;
    } else {
      h=(360+a.h-((360+a.h-b.h)%360)*fraction)%360;
    }
    return {'h': h,'s': a.s*(1-fraction)+b.s*fraction,'l': a.l*(1-fraction)+b.l*fraction};
  }

  // function hexToRgb(rgb) {
  //   rgb = rgb.replace(/^\s*#|\s*$/g, '');
  //   if(rgb.length === 3) {
  //       rgb = rgb.replace(/(.)/g, '$1$1');
  //   }
  //   return {'r':parseInt(rgb.substr(0, 2), 16),'g':parseInt(rgb.substr(2, 2), 16),'b':parseInt(rgb.substr(4, 2), 16)};
  // }

  function rgbToHex(rgb) {
    return '#' + ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1);
  }

  // function rgbToHsl(rgb) {
  //   var r = rgb.r / 255;
  //   var g = rgb.g / 255;
  //   var b = rgb.b / 255;
  //   var cMax = Math.max(r, g, b);
  //   var cMin = Math.min(r, g, b);
  //   var delta = cMax - cMin;
  //   var l = (cMax + cMin) / 2;
  //   var h = 0;
  //   var s = 0;
  //   if (delta === 0) {
  //     h = 0;
  //     s = 0;
  //   } else {
  //     if (cMax === r) {
  //       h = 60 * (((g - b) / delta) % 6);
  //     } else if (cMax === g) {
  //       h = 60 * (((b - r) / delta) + 2);
  //     } else {
  //       h = 60 * (((r - g) / delta) + 4);
  //     }
  //     s = (delta/(1-Math.abs(2*l - 1)));
  //   }
  //   return {'h': h,'s': s,'l': l};
  // }

  function hslToRgb(hsl) {
    var h = hsl.h;
    var s = hsl.s;
    var l = hsl.l;
    var c = (1 - Math.abs(2*l - 1)) * s;
    var x = c * (1 - Math.abs((h / 60) % 2 - 1));
    var m = l - c/ 2;
    var r, g, b;
    if (h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }
    return {'r':Math.floor(Math.max(0,(r + m))*255),'g':Math.floor(Math.max(0,(g + m))*255),'b':Math.floor(Math.max(0,(b + m)*255))};
  }

  $scope.getCapacityWidth = function(activity,multiplier) {
    if(activity.frm in $scope.getActivityAvailabilities()) {
      return (multiplier*Math.floor(1000000.0/(4*tools.getLength(activity.capacityHrs)-1))/10000.0).toString();
    }
  };

  $scope.getCapacityColor = function(activity,day,hrs) {
    var result;
    if(activity.frm in $scope.getActivityAvailabilities()) {
      if(hrs) {
        if('lastDeparture' in hrs) {
          result=$scope.getCapacityColor(activity,day,activity.capacityHrs[hrs.lastDeparture]);
        } else {
          var days=$scope.getActivityAvailabilities()[activity.frm].days;
          if(day in days && hrs.hrs in days[day].hrs) {
            var fraction=0;
            hrs=days[day].hrs[hrs.hrs];
            if(hrs.cap===0) {
              fraction=0;
            } else {
              fraction=hrs.load/hrs.cap;
            }
            if(fraction<=0.33) {
              result=rgbToHex(hslToRgb(interpolateHslColors({'h':80,'s':0.96,'l':0.50},{'h':35,'s':0.96,'l':0.50},fraction*3)));
            } else {
              result=rgbToHex(hslToRgb(interpolateHslColors({'h':35,'s':0.96,'l':0.50},{'h':15,'s':0.96,'l':0.50},(fraction-0.33)*3)));
            }
          } else {
            result='#fb484c';
          }
        }
      } else {
        result='#fb484c';
      }
    }
    return result;
  };

  $scope.getNextHrs = function(activity,previous) {
    var result=null;
    var hrs=previous.hrs;
    var allHrs=activity.capacityHrs;
    var minHrs='99:99:99';
    var i,testHrs;
    for(i in allHrs) {
      testHrs=allHrs[i].hrs;
      if(testHrs>hrs && testHrs<minHrs) {
        minHrs=testHrs;
      }
    }
    if(minHrs in allHrs) {
      result=allHrs[minHrs];
    }
    return result;
  };

  $scope.getNextCapacityColor = function(activity,day,previous) {
    return $scope.getCapacityColor(activity,day,$scope.getNextHrs(activity,previous));
  };

  $scope.getCapacityBorder = function(activity,day,hrs) {
    var result;
    if(day===activity.day) {
      if(activity.hrs==='') {
        if(tools.getLength(activity.days)>1) {
          if(hrs.hrs===$scope.getCapacityHrsLabel(activity,0)) {
            result='solid none solid solid';
          } else if(hrs.hrs===$scope.getCapacityHrsLabel(activity,1)) {
            result='solid solid solid none';
          } else {
            result='solid none';
          }
        }
      } else {
        var endtime=$scope.getEndtime(activity.hrs,activity.frm);
        if('lastDeparture' in hrs) {
          if($scope.getDurationHours(activity.frm)>0.5 && hrs.lastDeparture>=activity.hrs && hrs.lastDeparture<endtime) {
            result='solid solid solid none';
          } else {
            result='none';
          }
        } else {
          var nextHrs=$scope.getNextHrs(activity,hrs);
          if(hrs.hrs>=activity.hrs && hrs.hrs<endtime) {
            result='solid ';
            if(nextHrs.hrs<endtime || ($scope.getDurationHours(activity.frm)>0.5 && 'lastDeparture' in nextHrs)) {
              result+='none';
            } else {
              result+='solid';
            }
            result+=' solid ';
            if(hrs.hrs===activity.hrs) {
              result+='solid';
            } else {
              result+='none';
            }
          } else {
            result='none';
          }
        }
      }
    } else {
      result='none';
    }
    return result;
  };

  $scope.getCapacityBorderClass = function(activity,day,hrs) {
    var result='';
    if(day===activity.day) {
      if(activity.hrs==='') {
        if(tools.getLength(activity.days)>1) {
          if(hrs.hrs===$scope.getCapacityHrsLabel(activity,0)) {
            result='leftmost';
          } else if(hrs.hrs===$scope.getCapacityHrsLabel(activity,1)) {
            result='rightmost';
          } else {
            result='flanked';
          }
        }
      } else {
        var endtime=$scope.getEndtime(activity.hrs,activity.frm);
        if('lastDeparture' in hrs) {
          if($scope.getDurationHours(activity.frm)>0.5 && hrs.lastDeparture>=activity.hrs && hrs.lastDeparture<endtime) {
            result='rightmost';
          }
        } else {
          var nextHrs=$scope.getNextHrs(activity,hrs);
          if(hrs.hrs>=activity.hrs && hrs.hrs<endtime) {
            if(nextHrs.hrs<endtime || ($scope.getDurationHours(activity.frm)>0.5 && 'lastDeparture' in nextHrs)) {
              if(hrs.hrs===activity.hrs) {
                result='leftmost';
              } else {
                result='flanked';
              }
            } else if(hrs.hrs===activity.hrs) {
              result='single';
            } else {
              result='rightmost';
            }
          }
        }
      }
    }
    return result;
  };

  $scope.getCapacityNextBorder = function(activity,day,hrs) {
    var result;
    if(day===activity.day) {
      if(activity.hrs==='') {
        if(tools.getLength(activity.days)>1) {
          result='solid none';
        } else {
          result='none';
        }
      } else {
        var endtime=$scope.getEndtime(activity.hrs,activity.frm);
        var nextHrs=$scope.getNextHrs(activity,hrs);
        if(hrs.hrs>=activity.hrs && nextHrs!==null && (nextHrs.hrs<endtime || ($scope.getDurationHours(activity.frm)>0.5 && hrs.hrs<endtime && 'lastDeparture' in nextHrs))) {
          result='solid none';
        } else {
          result='none';
        }
      }
    } else {
      result='none';
    }
    return result;
  };

  $scope.getCapacityNextBorderClass = function(activity,day,hrs) {
    var result='';
    if(day===activity.day) {
      if(activity.hrs==='') {
        if(tools.getLength(activity.days)>1) {
          result='flanked';
        }
      } else {
        var endtime=$scope.getEndtime(activity.hrs,activity.frm);
        var nextHrs=$scope.getNextHrs(activity,hrs);
        if(hrs.hrs>=activity.hrs && nextHrs!==null && (nextHrs.hrs<endtime || ($scope.getDurationHours(activity.frm)>0.5 && hrs.hrs<endtime && 'lastDeparture' in nextHrs))) {
          result='flanked';
        }
      }
    }
    return result;
  };

  $scope.getChargingWording = function(hpdId) {
    var result;
    switch(parameters.HPD[hpdId].field2) {
      case 'Once':
        result=tools.lang({'en':'','nl':'','fr':''});
        break;
      case 'PerPerson':
        result=tools.lang({'en':'Per person','nl':'Per persoon','fr':'Par personne'});
        break;
      case 'PerTimeUnit':
        result=tools.lang({'en':'Per night','nl':'Per nacht','fr':'Par nuit'});
        break;
      case 'PerPersonPerTimeUnit':
        result=tools.lang({'en':'Per person per night','nl':'Per persoon per nacht','fr':'Par personne par nuit'});
        break;
      default:
        break;
    }
    return result;
  };

  $scope.activityHasValidFrm = function(activity) {
    return angular.isDefined(activity) && activity.frm!=='' && activity.frm in $scope.getActivityAvailabilities();
  };

  $scope.updateVoucherAmount = function(newItem) {
    var voucherProducts=tools.match(model.products,{'prd':'VOU'});
    for(var i in voucherProducts) {
      var item=model.items[voucherProducts[i].item];
      item.quantity=newItem.quantity;
    }
  };

  $scope.updateProductQuantity = function(product,formbuttoncomponentReference) {
    $scope.validateQuantity(product);
    var quantity=tools.numberIn(product.quantity);
    var item=model.items[product.item];
    var i;
    if(product.prd==='VOU' && product.isNotShadow && quantity>=1) {
      var voucherProducts=tools.match(model.products,{'prd':'VOU'});
      var currentQuantity=voucherProducts.length;
      if(quantity>currentQuantity) {
        var newItem,newProduct,newFormbuttoncomponentReference;
        for(i=currentQuantity;i<quantity;i++) {
          newItem=$scope.createItem(item.quantity);
          newItem.reductionamount='0';
          newItem.grossunitprice='1';
          newItem.netunitprice='1';
          modelstore.addRecord('items',newItem,model);
          newProduct={
            'id':tools.getMinIdMinusOne(tools.toArray(model.products).concat(tools.toArray(parentModel.products))),
            'visit':'-1',
            'item':newItem.id,
            'prd':'VOU',
            'isNotShadow':false,
            'comment':''
          };
          modelstore.addRecord('products',newProduct,model);
          newFormbuttoncomponentReference={
            'formbuttoncomponent':formbuttoncomponentReference.formbuttoncomponent,
            'record':newProduct.id
          };
          $scope.shortTermBasket.push(newFormbuttoncomponentReference);
        }
      } else if(quantity<currentQuantity) {
        var voucherProduct,excludeFormbuttoncomponentReference;
        voucherProducts=voucherProducts.sort(function(a,b) {
          return tools.numberIn(b.id)-tools.numberIn(a.id);
        });
        for(i=currentQuantity;i>quantity;i--) {
          voucherProduct=voucherProducts[i-1];
          modelstore.removeRecord('items',voucherProduct.item,model);
          modelstore.removeRecord('products',voucherProduct.id,model);
          excludeFormbuttoncomponentReference={
            'formbuttoncomponent':formbuttoncomponentReference.formbuttoncomponent,
            'record':voucherProduct.id
          };
          $scope.excludeFormbuttoncomponentreference([$scope.shortTermBasket],excludeFormbuttoncomponentReference);
          $scope.excludeFormbuttoncomponentreference(model.longTermBasket,excludeFormbuttoncomponentReference);
        }
      }
    } else if(product.prd!=='VOU' && product.isNotShadow) {
      item.quantity=product.quantity;
    }
  };

  $scope.componentProceed = function() {
    var collections=$scope.getEventsAndProducts($scope.shortTermBasket,model);
    var params=$scope.createReservationParams([],$scope.shortTermBasket,collections,false,model.longTermBasket);
    params.visit.campaign='';
    if(!parentModel.campaignOnlyForOnlinePayment) {
      params.visit.campaign=parentModel.campaignCode;
    }
    var hotelreservationcategoriesAreValid=true;
    var i,hotelreservationcategory;
    for(i in model.hotelreservationcategories) {
      hotelreservationcategory=model.hotelreservationcategories[i];
      if(!(hotelreservationcategory.requestedhsc in parentModel.hotelAvailabilities) || tools.amountIn(model.items[hotelreservationcategory.item].quantity)>parentModel.hotelAvailabilities[hotelreservationcategory.requestedhsc]) {
        errorlog.error(tools.lang({
          'en':'There are only',
          'nl':'Er zijn maar',
          'fr':'Il n\u0027y a que'
        })+' '+parentModel.hotelAvailabilities[hotelreservationcategory.requestedhsc]+' '+tools.label(parameters.HSC[hotelreservationcategory.requestedhsc])+' '+tools.lang({
          'en':'available',
          'nl':'beschikbaar',
          'fr':'disponible'
        }));
        hotelreservationcategoriesAreValid=false;
        break;
      }
    }
    if(hotelreservationcategoriesAreValid) {
      $scope.parentProceed($scope.shortTermBasket,collections,params);
    }
  };

  $scope.disableProceed = function(formbuttoncomponentReference) {
    var result=true;
    if(angular.isDefined(formbuttoncomponentReference)) {
      if(model.parkingMode) {
        result=tools.sum(model.items,'quantity')<1;
      } else {
        var formbuttoncomponent=parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent];
        var product;
        if(formbuttoncomponent.param1==='AC') {
          var activity=model.activities[formbuttoncomponentReference.record];
          result=activity.frm==='' || activity.day==='' || activity.hrs==='' || tools.join(activity,'participants',model).reduce(function(carry,participant) {
            return carry && tools.numberIn(model.items[participant.item].quantity)===0;
          },true);
        } else if(formbuttoncomponent.param1==='ME') {
          var meal=model.meals[formbuttoncomponentReference.record];
          result=tools.join(meal,'dishes',model).reduce(function(carry,dish) {
            return carry && tools.numberIn(model.items[dish.item].quantity)===0;
          },true);
        } else if(formbuttoncomponent.param1==='HS') {
          var hotelreservationcategory=model.hotelreservationcategories[formbuttoncomponentReference.record];
          if(hotelreservationcategory.isNotShadow) {
            result=tools.amountIn(hotelreservationcategory.quantity)===0.0;
          } else {
            result=false;
          }
        } else if(formbuttoncomponent.param1==='PR' && formbuttoncomponent.param2==='VOU') {
          product=model.products[formbuttoncomponentReference.record];
          if(product.isNotShadow) {
            result=(tools.amountIn(model.items[product.item].quantity)<tools.amountIn(parameters.PRD[product.prd].field1) && formbuttoncomponent.quantity===0.0) || tools.amountIn(product.quantity)===0.0;
          } else {
            result=false;
          }
        } else if(formbuttoncomponent.param1==='PR' && formbuttoncomponent.param2!=='VOU') {
          product=model.products[formbuttoncomponentReference.record];
          result=tools.amountIn(product.quantity)<tools.amountIn(parameters.PRD[product.prd].field1);
        } else if(formbuttoncomponent.param1==='PA') {
          result=tools.numberIn(model.packQuantity)===0;
        }
      }
    }
    return result;
  };

  $scope.validateQuantity = function(item,field) {
    field=angular.isUndefined(field)?'quantity':field;
    if(tools.amountIn(item[field])<0.0) {
      item[field]='0';
    }
  };

  // sum quantity of all hotelreservationcategory with same requestedHsc
  $scope.updateRoomQuantity = function(hotelreservationcategory,formbuttoncomponentReference) {
    if(hotelreservationcategory.isNotShadow) {
      $scope.validateQuantity(hotelreservationcategory);
      if(tools.amountIn(hotelreservationcategory.quantity)>parentModel.hotelAvailabilities[hotelreservationcategory.requestedhsc]) {
        hotelreservationcategory.quantity=parentModel.hotelAvailabilities[hotelreservationcategory.requestedhsc];
      }
      var quantity=tools.numberIn(hotelreservationcategory.quantity);
      if(quantity>=1 && hotelreservationcategory.isNotShadow) {
        var hotelreservationcategories=tools.toArray(model.hotelreservationcategories);
        var currentQuantity=tools.getLength(hotelreservationcategories);
        var i,j,hotelreservationproducts,hotelreservationproduct;
        if(quantity>currentQuantity) {
          var requestedhsc=hotelreservationcategory.requestedhsc;
          var hsc=parameters.HSC[requestedhsc];
          var item,productItem,hpdoption,newHotelreservationcategory,newFormbuttoncomponentReference;
          for(i=currentQuantity;i<quantity;i++) {
            item=$scope.createItem('1');
            modelstore.addRecord('items',item,model);
            newHotelreservationcategory={
              'isNotShadow':false,
              'id':tools.getMinIdMinusOne(tools.toArray(parentModel.hotelreservationcategories).concat(tools.toArray(model.hotelreservationcategories))),
              'visit':'-1',
              'htl':requestedhsc.substr(0,3),
              'item':item.id,
              'requestedhsc':requestedhsc,
              'startdate':parentModel.startdateISO,
              'starthrs':hotelreservationcategory.starthrs,
              'enddate':parentModel.enddateISO,
              'hra':hotelreservationcategory.hra,
              'adultcount':hsc.field3,
              'childcount':hsc.field4
            };
            for(j in hotelreservationcategory.hpdoptions) {
              hpdoption=hotelreservationcategory.hpdoptions[j];
              if(hpdoption.chosen) {
                productItem=$scope.createItem('1');
                modelstore.addRecord('items',productItem,model);
                modelstore.addRecord('hotelreservationproducts',{
                  'id':tools.getMinIdMinusOne(tools.toArray(parentModel.hotelreservationproducts).concat(tools.toArray(model.hotelreservationproducts))),
                  'reservationcategory':newHotelreservationcategory.id,
                  'hpd':hpdoption.id,
                  'item':productItem.id
                },model);
              }
            }
            newFormbuttoncomponentReference={
              'formbuttoncomponent':formbuttoncomponentReference.formbuttoncomponent,
              'record':newHotelreservationcategory.id
            };
            $scope.shortTermBasket.push(newFormbuttoncomponentReference);
            modelstore.addRecord('hotelreservationcategories',newHotelreservationcategory,model);
          }
        } else if(quantity<currentQuantity) {
          var oldHotelreservationcategory,excludeFormbuttoncomponentReference;
          hotelreservationcategories=hotelreservationcategories.sort(function(a,b) {
            return tools.numberIn(b.id)-tools.numberIn(a.id);
          });
          for(i=currentQuantity;i>quantity;i--) {
            oldHotelreservationcategory=hotelreservationcategories[i-1];
            hotelreservationproducts=tools.join(oldHotelreservationcategory,'hotelreservationproducts',model);
            for(j in hotelreservationproducts) {
              hotelreservationproduct=hotelreservationproducts[j];
              modelstore.removeRecord('items',hotelreservationproduct.item,model);
              modelstore.removeRecord('hotelreservationproducts',hotelreservationproduct.id,model);
            }
            modelstore.removeRecord('items',oldHotelreservationcategory.item,model);
            modelstore.removeRecord('hotelreservationcategories',oldHotelreservationcategory.id,model);
            excludeFormbuttoncomponentReference={
              'formbuttoncomponent':formbuttoncomponentReference.formbuttoncomponent,
              'record':oldHotelreservationcategory.id
            };
            $scope.excludeFormbuttoncomponentreference([$scope.shortTermBasket],excludeFormbuttoncomponentReference);
            $scope.excludeFormbuttoncomponentreference(model.longTermBasket,excludeFormbuttoncomponentReference);
          }
        }
      }
    }
  };

  $scope.changeHpdOption = function(hpdoption) {
    var i,j,hotelreservationcategory,matchingHotelreservationproducts,hotelreservationproduct,item;
    var hotelreservationcategories=model.hotelreservationcategories;
    for(i in hotelreservationcategories) {
      hotelreservationcategory=hotelreservationcategories[i];
      matchingHotelreservationproducts=tools.match(tools.join(hotelreservationcategory,'hotelreservationproducts',model),{'hpd':hpdoption.id});
      if(hpdoption.chosen) {
        if(matchingHotelreservationproducts.length===0) {
          item=$scope.createItem('1');
          modelstore.addRecord('items',item,model);
          modelstore.addRecord('hotelreservationproducts',{
            'id':tools.getMinIdMinusOne(tools.toArray(parentModel.hotelreservationproducts).concat(tools.toArray(model.hotelreservationproducts))),
            'reservationcategory':hotelreservationcategory.id,
            'hpd':hpdoption.id,
            'item':item.id
          },model);
        }
      } else {
        if(matchingHotelreservationproducts.length>0) {
          for(j in matchingHotelreservationproducts) {
            hotelreservationproduct=matchingHotelreservationproducts[j];
            modelstore.removeRecord('items',hotelreservationproduct.item,model);
            modelstore.removeRecord('hotelreservationproducts',hotelreservationproduct.id,model);
          }
        }
      }
    }
  };

  $scope.onPackQuantityChange = function() {
    $scope.validateQuantity(model,'packQuantity');
    var sumGrossunitprice=0.0;
    var sumNetunitprice=0.0;
    angular.forEach($scope.shortTermBasket,function(formbuttoncomponentReference) {
      var i,j,formbuttoncomponent,participants,participant,item,itemb,itemc,dishes,hotelreservationcategories,hotelreservationcategory,hotelreservationproducts;
      if('record' in formbuttoncomponentReference) {
        formbuttoncomponent=parentModel.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent];
        item=null;
        if(formbuttoncomponent.param1==='AC') {
          participants=tools.join(model.activities[formbuttoncomponentReference.record],'participants',model);
          for(i in participants) {
            model.items[participants[i].item].quantity='0';
          }
          participant=participants.reduce(function(acc,participantCandidate) {
            return parameters.UNT[participantCandidate.unt].field2==='1'?participantCandidate:acc;
          },null);
          if(participant!==null) {
            item=model.items[participant.item];
          }
        } else if(formbuttoncomponent.param1==='ME') {
          dishes=tools.join(model.meals[formbuttoncomponentReference.record],'dishes',model);
          for(i in dishes) {
            itemb=model.items[dishes[i].item];
            itemb.quantity=tools.amountIn(formbuttoncomponent.quantity)*tools.numberIn(model.packQuantity);
            sumGrossunitprice+=tools.amountIn(itemb.grossunitprice)*tools.numberIn(formbuttoncomponent.quantity);
            sumNetunitprice+=tools.amountIn(itemb.netunitprice)*tools.numberIn(formbuttoncomponent.quantity);
          }
        } else if(formbuttoncomponent.param1==='HS') {
          hotelreservationcategory=model.hotelreservationcategories[formbuttoncomponentReference.record];
          hotelreservationcategory.quantity=tools.amountIn(formbuttoncomponent.quantity)*tools.numberIn(model.packQuantity);
          hotelreservationcategories=model.hotelreservationcategories;
          for(i in hotelreservationcategories) {
            hotelreservationcategory=hotelreservationcategories[i];
            itemb=model.items[hotelreservationcategory.item];
            itemb.quantity=tools.amountIn(formbuttoncomponent.quantity)*tools.numberIn(model.packQuantity);
            sumGrossunitprice+=tools.amountIn(itemb.grossunitprice)*tools.numberIn(formbuttoncomponent.quantity);
            sumNetunitprice+=tools.amountIn(itemb.netunitprice)*tools.numberIn(formbuttoncomponent.quantity);
            hotelreservationproducts=tools.join(hotelreservationcategory,'hotelreservationproducts',model);
            for(j in hotelreservationproducts) {
              itemc=model.items[hotelreservationproducts[j].item];
              sumGrossunitprice+=tools.amountIn(itemc.grossunitprice)*tools.amountIn(formbuttoncomponent.quantity);
              sumNetunitprice+=tools.amountIn(itemc.netunitprice)*tools.amountIn(formbuttoncomponent.quantity);
            }
          }
        } else if(formbuttoncomponent.param1==='PR') {
          item=model.items[model.products[formbuttoncomponentReference.record].item];
        }
        if(item!==null) {
          item.quantity=tools.amountIn(formbuttoncomponent.quantity)*tools.numberIn(model.packQuantity);
          sumGrossunitprice+=tools.amountIn(item.grossunitprice)*tools.numberIn(formbuttoncomponent.quantity);
          sumNetunitprice+=tools.amountIn(item.netunitprice)*tools.numberIn(formbuttoncomponent.quantity);
        }
      }
    });
    model.packGrossunitprice=sumGrossunitprice;
    model.packNetunitprice=sumNetunitprice;
  };

  tools.initAttributes(model,{
    'filteredShortTermBasket':[],
    'longTermBasket':[],
    'activities':{},
    'participants':{},
    'meals':{},
    'dishes':{},
    'hotelreservationcategories':{},
    'hotelreservationproducts':{},
    'products':{},
    'items':{},
    'parkingMode':false,
    'packQuantity':'0',
    'packGrossunitprice':0.0,
    'packNetunitprice':0.0,
    'hasfixeddate':false,
    'takeawayHrsOptions':[],
    'calculatingPrices':false,
    'firstFormbutton':null
  });
  $scope.$watch('model.accordionStatus',function(newValue,oldValue) {
    if(!oldValue) {
      return;
    }
    if(oldValue.length>0 && !newValue.reduce(function(carry,open) {
      return carry||open;
    },false)) {
      model.accordionStatus=oldValue;
    }
  },true);

  $scope.loaded.then(function() {
    if(parentModel.detailsPageCollections!==null) {
      var collectionName,collection,i,record;
      for(collectionName in parentModel.detailsPageCollections) {
        collection=parentModel.detailsPageCollections[collectionName];
        for(i in collection) {
          record=collection[i];
          modelstore.addRecord(collectionName,record,model);
        }
      }
    }
    $scope.load();
  });

}]);
